import React from "react";

const UserCircled = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94038 23.2909C8.23999 22.2937 8.85308 21.4197 9.68868 20.7984C10.5243 20.1772 11.5379 19.8418 12.5791 19.8421H17.4212C18.4638 19.8418 19.4786 20.1779 20.3148 20.8006C21.151 21.4233 21.7639 22.2992 22.0624 23.2982M4.10547 15C4.10547 16.4307 4.38727 17.8475 4.93478 19.1693C5.48229 20.4911 6.28479 21.6921 7.29646 22.7038C8.30813 23.7154 9.50916 24.5179 10.831 25.0655C12.1528 25.613 13.5695 25.8948 15.0002 25.8948C16.4309 25.8948 17.8476 25.613 19.1694 25.0655C20.4913 24.5179 21.6923 23.7154 22.7039 22.7038C23.7156 21.6921 24.5181 20.4911 25.0656 19.1693C25.6131 17.8475 25.8949 16.4307 25.8949 15C25.8949 13.5693 25.6131 12.1526 25.0656 10.8308C24.5181 9.50898 23.7156 8.30796 22.7039 7.29629C21.6923 6.28462 20.4913 5.48212 19.1694 4.93461C17.8476 4.38709 16.4309 4.10529 15.0002 4.10529C13.5695 4.10529 12.1528 4.38709 10.831 4.93461C9.50916 5.48212 8.30813 6.28462 7.29646 7.29629C6.28479 8.30796 5.48229 9.50898 4.93478 10.8308C4.38727 12.1526 4.10547 13.5693 4.10547 15ZM11.3686 12.579C11.3686 13.5421 11.7512 14.4658 12.4323 15.1469C13.1133 15.8279 14.0371 16.2106 15.0002 16.2106C15.9634 16.2106 16.8871 15.8279 17.5681 15.1469C18.2492 14.4658 18.6318 13.5421 18.6318 12.579C18.6318 11.6158 18.2492 10.6921 17.5681 10.0111C16.8871 9.33001 15.9634 8.9474 15.0002 8.9474C14.0371 8.9474 13.1133 9.33001 12.4323 10.0111C11.7512 10.6921 11.3686 11.6158 11.3686 12.579Z"
        stroke="#292D32"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UserCircled;
