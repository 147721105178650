import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { useState } from "react";

const mapStyles = {
  width: "100%",
  height: "50%",
};

const GoogleMap = ({ address, onSelect, height }) => {
  const center = {
    lat: address ? parseFloat(address?.lat) : 40.4093,
    lng: address ? parseFloat(address?.lon) : 49.867092,
  };

  const [selectedPosition, setSelectedPosition] = useState(center);

  const handleMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setSelectedPosition({ lat, lng });
    onSelect && onSelect({ lat, lng });
  };

  return (
    <div
      style={{
        height: height ? height : "400px",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <Map
        google={window.google}
        zoom={address ? 15 : 12}
        style={{
          height: height ? height : "400px",
          width: "100%",
          borderRadius: 14,
        }}
        initialCenter={center}
        onClick={onSelect ? handleMapClick : null}
      >
        <Marker position={selectedPosition} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
