import React from "react";

const SliderArrowRight = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        transform="matrix(1 0 0 -1 0.000488281 32)"
        fill="white"
        fill-opacity="0.2"
      />
      <path
        d="M17.0005 13L14.0005 16L17.0005 19M25.0005 16C25.0005 20.9706 20.9711 25 16.0005 25C11.0299 25 7.00049 20.9706 7.00049 16C7.00049 11.0294 11.0299 7 16.0005 7C20.9711 7 25.0005 11.0294 25.0005 16Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SliderArrowRight;
