import React from "react";

const PinkDimond = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="14" fill="#FF2869" />
      <path
        d="M18.2468 20L16.4924 18.0702L17.0187 17.193M14.738 13.8596H25.2643L27.8959 18.2456L20.4398 26.5789C20.3826 26.6373 20.3144 26.6837 20.2391 26.7153C20.1637 26.7469 20.0829 26.7632 20.0012 26.7632C19.9195 26.7632 19.8386 26.7469 19.7633 26.7153C19.688 26.6837 19.6198 26.6373 19.5626 26.5789L12.1064 18.2456L14.738 13.8596Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PinkDimond;
