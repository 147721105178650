import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import styles from "./app.module.css";

// ICONS
import Logo from "./components/icons/Logo";
import Dimond from "./components/icons/PinkDimond";
import Star from "./components/icons/RatingStarWhite";
import User from "./components/icons/UserCircled";
import Alert from "./components/icons/AlertSquare";
import Bed from "./components/icons/Bed";
import Square from "./components/icons/SquareHalf";
import Script from "./components/icons/Script";
import Stairs from "./components/icons/Stairs";
import Paint from "./components/icons/Paint";
import Discount from "./components/icons/Discount";
import MetroLogo from "./images/metroLogo.png";

import ProductGallery from "./components/ProductGallery";
import Map from "./components/Map";
import { formatPrice } from "./utils/formatPrice";

import AZ from "./az.json";

function App() {
  const params = useParams();
  // console.log("params", params);

  const [product, setProduct] = useState({});
  const [author, setAuthor] = useState({});

  const getElan = async () => {
    try {
      const { data } = await axios.get(
        `https://api.milliemlak.az/elan/detail/${params.post_id}`
      );
      setProduct(data?.data?.product);
      setAuthor(data?.data?.author_details);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getElan();
  }, []);

  return (
    <>
      {product?.price && (
        <div className={styles.container}>
          <div className={styles.pageHeader}>
            <Logo />
            <div className={styles.pageHeaderRight}>
              {product?.is_premium && (
                <div className={styles.pageHeaderIconWrp}>
                  <span>Bu elan premium elandır.</span>
                  <Dimond />
                </div>
              )}
              <div className={styles.pageHeaderIconWrp}>
                <div className={styles.ratingIconWrp}>
                  <Star />
                  <span>{product?.rating ?? 0}</span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.middleSection}>
            <div className={styles.middleLeft}>
              <ProductGallery
                galleryID="my-test-gallery"
                images={product?.images}
              />
            </div>
            <div className={styles.middleRight}>
              <div className={styles.metaInfoCard}>
                <div className={styles.metaInfoTop}>
                  <span className={styles.pricePerKv}>
                    1 kv m2 /{" "}
                    {formatPrice(
                      Math.floor(
                        product?.price / parseInt(product?.indoor_area)
                      )
                    )}{" "}
                    AZN
                  </span>
                  <p className={styles.price}>
                    {formatPrice(product?.price)} <span>AZN</span>
                  </p>
                  <div className={styles.bullets}>
                    {product?.room_count && (
                      <div className={styles.bullet}>
                        <span className={styles.bulletTxt}>
                          {product?.room_count} otaqli
                        </span>
                        <span className={styles.bulletDot}> •</span>
                      </div>
                    )}
                    {product?.elan_type && (
                      <div className={styles.bullet}>
                        <span className={styles.bulletTxt}>
                          {AZ[product?.elan_type]}
                        </span>
                        <span className={styles.bulletDot}> •</span>
                      </div>
                    )}
                    {product?.indoor_area && (
                      <div className={styles.bullet}>
                        <span className={styles.bulletTxt}>
                          {product?.indoor_area} m²
                        </span>
                        <span className={styles.bulletDot}> •</span>
                      </div>
                    )}
                    {product?.metro_station && (
                      <div className={styles.bullet}>
                        <span className={styles.bulletTxt}>
                          {product?.metro_station}
                        </span>
                        <span className={styles.bulletDot}> •</span>
                      </div>
                    )}
                  </div>
                  <p className={styles.addressTxt}>Ünvan: {product?.address}</p>
                </div>
                <div className={styles.metaInfoMid}>
                  <div className={styles.profileSection}>
                    <div className={styles.avatar}>
                      <User />
                    </div>
                    <div className={styles.nameSection}>
                      <p className={styles.nameTxt}>{author?.username}</p>
                      <p className={styles.role}>Mülkiyyətçi</p>
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <a
                      href={`tel:${author?.phone}`}
                      className={styles.contactBtn}
                    >
                      <span>Əlaqə:</span>
                      <span>{author?.phone}</span>
                    </a>
                    {product?.mortgage && (
                      <div className={styles.ipotekaBtn}>
                        <span>İpoteka ilə al</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.warningSection}>
                    <div className={styles.warningIconWrp}>
                      <Alert />
                    </div>
                    <div className={styles.warningContent}>
                      <p className={styles.attentionTxt}>Diqqət!</p>
                      <p className={styles.warningTxt}>
                        Daşınmaz əmlaka baxış keçirmədən heç vaxt beh (avans)
                        göndərməyin.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.metaInfoBottom}>
                  <div className={styles.elanStatistics}>
                    <div className={styles.statisticWrp}>
                      <p className={styles.statisticKey}>Elanın nömrəsi</p>
                      <p className={styles.statisticValue}>{product?.id}</p>
                    </div>
                    <div className={styles.statisticWrp}>
                      <p className={styles.statisticKey}>Baxışların sayı</p>
                      <p className={styles.statisticValue}>{product?.views}</p>
                    </div>
                    <div className={styles.statisticWrp}>
                      <p className={styles.statisticKey}>Yeniləndi</p>
                      <p className={styles.statisticValue}>
                        {product?.updated}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomSection}>
            <div className={styles.bottomCard}>
              <div className={styles.bottomCardTop}>
                <div className={styles.bottomCardTitleSection}>
                  <p className={styles.title}>
                    {`${
                      product?.room_count && product?.room_count + " otaqli"
                    } ${AZ[product?.elan_type]?.toLowerCase()} ${
                      product?.indoor_area
                    } m² ${product?.metro_station ?? ""}`}
                  </p>
                  <p className={styles.address}>{product?.address}</p>
                </div>
                <div className={styles.ratingAndDateSec}>
                  <div className={styles.ratingSection}>
                    <span className={styles.rating}>
                      {product?.rate_count ?? 0}
                    </span>
                    <span className={styles.ratingTxt}> reytinq</span>
                  </div>
                  <div className={styles.postDate}>
                    <span className={styles.postedTxt}>Paylaşılıb: </span>
                    <span className={styles.postedDate}>
                      {" "}
                      {product?.created}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.elanFiltersSection}>
                <div className={styles.elanFilters}>
                  <div className={styles.binaryElanFilter}>
                    {product?.room_count && (
                      <div className={styles.elanFilter}>
                        <div className={styles.elanFilterIconWrp}>
                          <Bed />
                        </div>
                        <span className={styles.elanFilterTxt}>
                          {product?.room_count} otaqli
                        </span>
                      </div>
                    )}
                    {product?.indoor_area && (
                      <div className={styles.elanFilter}>
                        <div className={styles.elanFilterIconWrp}>
                          <Square />
                        </div>
                        <span className={styles.elanFilterTxt}>
                          Sahəsi {product?.indoor_area} M2
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={styles.binaryElanFilter}>
                    {product?.title_deed && (
                      <div className={styles.elanFilter}>
                        <div className={styles.elanFilterIconWrp}>
                          <Script />
                        </div>
                        <span className={styles.elanFilterTxt}>
                          Evin çıxarışı var
                        </span>
                      </div>
                    )}
                    {product?.floor_of_property && (
                      <div className={styles.elanFilter}>
                        <div className={styles.elanFilterIconWrp}>
                          <Stairs />
                        </div>
                        <span className={styles.elanFilterTxt}>
                          {product?.floor_of_property}/
                          {product?.floor_of_building} mərtəbə
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={styles.binaryElanFilter}>
                    {product?.condition && (
                      <div className={styles.elanFilter}>
                        <div className={styles.elanFilterIconWrp}>
                          <Paint />
                        </div>
                        <span className={styles.elanFilterTxt}>
                          {AZ[product?.condition]}
                        </span>
                      </div>
                    )}
                    {product?.mortgage && (
                      <div className={styles.elanFilter}>
                        <div className={styles.elanFilterIconWrp}>
                          <Discount />
                        </div>
                        <span className={styles.elanFilterTxt}>
                          İpotekaya verilir
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.descriptionSection}>
                <p className={styles.descSecTitle}>Qısa məlumat</p>
                <p className={styles.descriptionContent}>
                  {product?.description}
                </p>
              </div>
              <div>
                <Map
                  address={{
                    lat: product?.map?.longitude,
                    lon: product?.map?.latitude,
                  }}
                  height="217px"
                />
              </div>
              {product?.metro_station && (
                <div className={styles.metroSection}>
                  <div className={styles.metroWrp}>
                    <img src={MetroLogo} alt="Metro" />
                    <span>{product?.metro_station}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
