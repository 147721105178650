import React from "react";

const RatingStarWhite = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80816 4.28168L1.08649 4.82127L1.02057 4.83468C0.920789 4.86117 0.829822 4.91367 0.756962 4.98682C0.684102 5.05996 0.63196 5.15113 0.605861 5.25102C0.579762 5.35091 0.580641 5.45594 0.608407 5.55538C0.636174 5.65481 0.689834 5.7451 0.763908 5.81702L3.46007 8.44143L2.82424 12.1485L2.81666 12.2127C2.81055 12.3159 2.83198 12.4189 2.87875 12.5111C2.92553 12.6033 2.99597 12.6814 3.08285 12.7374C3.16974 12.7934 3.26996 12.8254 3.37324 12.8299C3.47653 12.8345 3.57917 12.8115 3.67066 12.7633L6.99916 11.0133L10.3201 12.7633L10.3784 12.7902C10.4747 12.8281 10.5793 12.8397 10.6816 12.8239C10.7839 12.808 10.8801 12.7652 10.9603 12.6999C11.0406 12.6346 11.1021 12.5491 11.1384 12.4522C11.1747 12.3553 11.1846 12.2505 11.1671 12.1485L10.5307 8.44143L13.228 5.81643L13.2735 5.76685C13.3385 5.6868 13.3811 5.59095 13.397 5.48906C13.4129 5.38718 13.4015 5.2829 13.364 5.18686C13.3264 5.09081 13.2641 5.00643 13.1834 4.94232C13.1026 4.8782 13.0063 4.83663 12.9042 4.82185L9.18257 4.28168L7.51891 0.910016C7.47077 0.812328 7.39624 0.730066 7.30377 0.672543C7.21129 0.615021 7.10456 0.584534 6.99566 0.584534C6.88675 0.584534 6.78002 0.615021 6.68755 0.672543C6.59507 0.730066 6.52055 0.812328 6.47241 0.910016L4.80816 4.28168Z"
        fill="white"
      />
    </svg>
  );
};

export default RatingStarWhite;
