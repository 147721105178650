import React from "react";

const AlertSquare = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L12.642 2.005L13.258 2.022L13.557 2.035L14.136 2.069L14.689 2.115C19.376 2.57 21.339 4.448 21.855 9.021L21.885 9.311L21.931 9.864L21.972 10.591L21.978 10.741L21.995 11.358L22 12L21.995 12.642L21.978 13.258L21.965 13.557L21.931 14.136L21.885 14.689C21.43 19.376 19.552 21.339 14.979 21.855L14.689 21.885L14.136 21.931L13.409 21.972L13.259 21.978L12.642 21.995L12 22L11.358 21.995L10.742 21.978L10.443 21.965L9.86398 21.931L9.31098 21.885C4.62398 21.43 2.66098 19.552 2.14498 14.979L2.11498 14.689L2.06898 14.136L2.02798 13.409L2.02198 13.259L2.00498 12.642L2.00098 12.324V11.676L2.00498 11.358L2.02198 10.742L2.03498 10.443L2.06898 9.864L2.11498 9.311C2.56998 4.624 4.44798 2.661 9.02098 2.145L9.31098 2.115L9.86398 2.069L10.591 2.028L10.741 2.022L11.358 2.005C11.568 2.002 11.782 2 12 2ZM12.01 15L11.883 15.007C11.6399 15.0359 11.4159 15.153 11.2534 15.336C11.0909 15.519 11.0011 15.7552 11.0011 16C11.0011 16.2448 11.0909 16.481 11.2534 16.664C11.4159 16.847 11.6399 16.9641 11.883 16.993L12 17L12.127 16.993C12.37 16.9641 12.594 16.847 12.7566 16.664C12.9191 16.481 13.0089 16.2448 13.0089 16C13.0089 15.7552 12.9191 15.519 12.7566 15.336C12.594 15.153 12.37 15.0359 12.127 15.007L12.01 15ZM12 7C11.755 7.00003 11.5186 7.08996 11.3356 7.25272C11.1526 7.41547 11.0356 7.63975 11.007 7.883L11 8V12L11.007 12.117C11.0359 12.36 11.1529 12.5841 11.3359 12.7466C11.519 12.9091 11.7552 12.9989 12 12.9989C12.2447 12.9989 12.481 12.9091 12.664 12.7466C12.847 12.5841 12.9641 12.36 12.993 12.117L13 12V8L12.993 7.883C12.9643 7.63975 12.8474 7.41547 12.6643 7.25272C12.4813 7.08996 12.2449 7.00003 12 7Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default AlertSquare;
