// import React, { useEffect, useRef, useState } from "react";
// import ImageGallery from "react-image-gallery";
// import PhotoSwipeLightbox from "photoswipe/lightbox";
// import cn from "classnames";

// import "photoswipe/style.css";
// import styles from "./productGallery.module.css";

import SliderArrowRight from "../icons/SliderArrowRight";
import SliderArrowLeft from "../icons/SliderArrowLeft";
import ChevronRight from "../icons/ChevronRight";
// import Close from "../icons/Close";

import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import cn from "classnames";
import PhotoSwipeLightbox from "photoswipe/lightbox";

import "photoswipe/style.css";
import styles from "./productGallery.module.css";

// import product from "/product.json";

// import SliderArrowRight from "/public/icons/sliderArrowRight.svg";
// import SliderArrowLeft from "/public/icons/sliderArrowLeft.svg";
// import ChevronRight from "/public/icons/chevronRight.svg";

const ProductGallery = ({ images }) => {
  const firstThumbnailRef = useRef(null);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={cn(styles.sliderArrowRight, className)}
        onClick={onClick}
        style={{ ...style }}
      >
        <SliderArrowRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={cn(styles.sliderArrowLeft, className)}
        onClick={onClick}
        style={{ ...style }}
      >
        <SliderArrowLeft />
      </div>
    );
  };

  const smallImagesFiltered = images;
  var settings = {
    customPaging: function (i) {
      return (
        <a className={styles.dotImageWrp}>
          <img className={styles.dotImage} src={smallImagesFiltered[i]} />
        </a>
      );
    },
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const bigImagesFiltered = images?.slice(0, 7);

  const changeActiveSlide = (index) => {
    const activeElement = document.querySelector(".slick-active");
    const currentElement = document.querySelector(".slick-current");
    activeElement.classList.remove("slick-active");
    currentElement.classList.remove("slick-current");
    activeElement.setAttribute("aria-hidden", "true");

    const slickElement = document.querySelector(`[data-index="${index}"]`);

    slickElement.classList.add("slick-active");
    slickElement.classList.add("slick-current");
    slickElement.setAttribute("aria-hidden", "false");
  };

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#my-test-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });

    // lightbox.on("uiRegister", function () {
    //   lightbox.pswp.ui.registerElement({
    //     name: "bulletsIndicator",
    //     className: "pswp__bullets-indicator pswp__bullets-wrp ",
    //     appendTo: "wrapper",
    //     onInit: (el, pswp) => {
    //       const bullets = [];
    //       let bullet;
    //       let prevIndex = -1;
    //       let thumbImages = product.images_small;

    //       for (let i = 0; i < pswp.getNumItems(); i++) {
    //         bullet = document.createElement("div");
    //         bullet.className = "pswp__bullet";
    //         bullet.style.background = `url('${thumbImages[i]}')`;
    //         bullet.onclick = (e) => {
    //           pswp.goTo(bullets.indexOf(e.target));
    //         };
    //         el.appendChild(bullet);
    //         bullets.push(bullet);
    //       }

    //       pswp.on("change", (a) => {
    //         if (prevIndex >= 0) {
    //           bullets[prevIndex].classList.remove("pswp__bullet--active");
    //         }
    //         bullets[pswp.currIndex].classList.add("pswp__bullet--active");
    //         prevIndex = pswp.currIndex;
    //       });
    //     },
    //   });
    // });

    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, [images]);

  return (
    <div className={styles.container}>
      <div>
        <Slider
          {...settings}
          className={cn("customProductSlider", styles.slider)}
        >
          {images?.map((img, index) => (
            <div className={styles.sliderImgWrp}>
              <img src={img} className={styles.sliderImg} />
            </div>
          ))}
        </Slider>
      </div>
      {/* <div className={styles.sliderThumbnails}>
        {images?.slice(0, 7)?.map((i, index) => (
          <div
            className={styles.sliderThumbnail}
            onClick={() => changeActiveSlide(index)}
            style={{ backgroundImage: `url(${decodeURIComponent(i)})` }}
          ></div>
        ))}
      </div> */}
      <div
        className={cn("pswp-gallery", styles.sliderThumbnails)}
        id="my-test-gallery"
      >
        {images?.map((image, index) => (
          <a
            href={image}
            // data-pswp-width={image.width}
            // data-pswp-height={image.height}
            key={"my-test-gallery" + "-" + index}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={styles.sliderThumbnail}
              // onClick={() => changeActiveSlide(index)}
              style={{ backgroundImage: `url(${decodeURIComponent(image)})` }}
            >
              {index == 6 && (
                <div className={styles.seeAllBtn}>
                  <span>Hamısı</span>
                  <ChevronRight className={styles.chevronRight} />
                </div>
              )}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProductGallery;

// const ProductGallery = ({ images, showFullscreenButton = true }) => {
//   // const [images, setImages] = useState([{}]);

//   const galleryRef = useRef(null);

//   const [isFullScreen, setIsFullScreen] = useState(false);

//   // useEffect(() => {
//   //   const newState = product?.images_big?.map((bigImage, index) => ({
//   //     original: bigImage,
//   //     thumbnail: product.images_small[index],
//   //   }));

//   //   console.log("newsra", newState);

//   //   setImages(newState);
//   // }, []);

//   useEffect(() => {
//     if (isFullScreen) {
//       document.body.classList.add("noscroll");
//     } else {
//       document.body.classList.remove("noscroll");
//     }
//   }, [isFullScreen]);

//   return (
//     <ImageGallery
//       items={images}
//       useBrowserFullscreen={false}
//       ref={galleryRef}
//       onThumbnailClick={() => {
//         !isFullScreen && galleryRef.current.toggleFullScreen();
//       }}
//       showPlayButton={false}
//       showFullscreenButton={showFullscreenButton}
//       slideOnThumbnailOver
//       // disableThumbnailScroll
//       renderFullscreenButton={(onClick, isFullScreen) => {
//         setIsFullScreen(isFullScreen);
//         return (
//           <div
//             className={cn(
//               styles.fullscreenCloseIcon,
//               "image-gallery-fullscreen-button-custom"
//             )}
//             onClick={onClick}
//           >
//             <Close />
//           </div>
//         );
//       }}
//       slideDuration={0}
//       renderLeftNav={(onClick, disabled) => (
//         <div className={styles.leftArrow} onClick={onClick} disabled={disabled}>
//           <SliderArrowLeft />
//         </div>
//       )}
//       renderRightNav={(onClick, disabled) => (
//         <div
//           className={styles.rightArrow}
//           onClick={onClick}
//           disabled={disabled}
//         >
//           <SliderArrowRight />
//         </div>
//       )}
//     />
//   );
// };

// export default ProductGallery;
