import React from "react";

const Paint = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.8337 5H16.667C17.109 5 17.5329 5.17559 17.8455 5.48816C18.1581 5.80072 18.3337 6.22464 18.3337 6.66667C18.3337 7.77174 17.8947 8.83154 17.1133 9.61294C16.3319 10.3943 15.2721 10.8333 14.167 10.8333H10.0003V12.5M4.16699 4.16667C4.16699 3.72464 4.34259 3.30072 4.65515 2.98816C4.96771 2.67559 5.39163 2.5 5.83366 2.5H14.167C14.609 2.5 15.0329 2.67559 15.3455 2.98816C15.6581 3.30072 15.8337 3.72464 15.8337 4.16667V5.83333C15.8337 6.27536 15.6581 6.69928 15.3455 7.01184C15.0329 7.3244 14.609 7.5 14.167 7.5H5.83366C5.39163 7.5 4.96771 7.3244 4.65515 7.01184C4.34259 6.69928 4.16699 6.27536 4.16699 5.83333V4.16667ZM8.33366 13.3333C8.33366 13.1123 8.42146 12.9004 8.57774 12.7441C8.73402 12.5878 8.94598 12.5 9.16699 12.5H10.8337C11.0547 12.5 11.2666 12.5878 11.4229 12.7441C11.5792 12.9004 11.667 13.1123 11.667 13.3333V16.6667C11.667 16.8877 11.5792 17.0996 11.4229 17.2559C11.2666 17.4122 11.0547 17.5 10.8337 17.5H9.16699C8.94598 17.5 8.73402 17.4122 8.57774 17.2559C8.42146 17.0996 8.33366 16.8877 8.33366 16.6667V13.3333Z"
        stroke="#0094FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Paint;
