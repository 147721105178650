import React from "react";

const Script = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.1667 16.6666H5C4.33696 16.6666 3.70107 16.4033 3.23223 15.9344C2.76339 15.4656 2.5 14.8297 2.5 14.1666C2.5 13.5036 2.76339 12.8677 3.23223 12.3989C3.70107 11.93 4.33696 11.6666 5 11.6666H14.1667C13.5036 11.6666 12.8677 11.93 12.3989 12.3989C11.9301 12.8677 11.6667 13.5036 11.6667 14.1666C11.6667 14.8297 11.9301 15.4656 12.3989 15.9344C12.8677 16.4033 13.5036 16.6666 14.1667 16.6666ZM14.1667 16.6666H15C15.663 16.6666 16.2989 16.4033 16.7678 15.9344C17.2366 15.4656 17.5 14.8297 17.5 14.1666V4.99998C17.5 4.55795 17.3244 4.13403 17.0118 3.82147C16.6993 3.50891 16.2754 3.33331 15.8333 3.33331H7.5C7.05797 3.33331 6.63405 3.50891 6.32149 3.82147C6.00893 4.13403 5.83333 4.55795 5.83333 4.99998V11.6666"
        stroke="#0094FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Script;
