import React from "react";

const Bed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.5 5.83331V15M2.5 11.6666H17.5M17.5 15V8.33331C17.5 7.89129 17.3244 7.46736 17.0118 7.1548C16.6993 6.84224 16.2754 6.66665 15.8333 6.66665H9.16667V11.6666M5 8.33331C5 8.55433 5.0878 8.76629 5.24408 8.92257C5.40036 9.07885 5.61232 9.16665 5.83333 9.16665C6.05435 9.16665 6.26631 9.07885 6.42259 8.92257C6.57887 8.76629 6.66667 8.55433 6.66667 8.33331C6.66667 8.1123 6.57887 7.90034 6.42259 7.74406C6.26631 7.58778 6.05435 7.49998 5.83333 7.49998C5.61232 7.49998 5.40036 7.58778 5.24408 7.74406C5.0878 7.90034 5 8.1123 5 8.33331Z"
        stroke="#0094FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Bed;
